<template>
	<div>
		<div class='a-favourite'>
			<div class="app-container a-favourite-container">
				<div class='a-favourite-header'>
					<v-icon icon='favourite' :size='20' />
					<p class="a-heading-1">{{$t("routes.favourite")}}</p>
				</div>
				<div class='a-favourite-content' v-if='loading'>
					<div class="app-accordion__item" v-for="(group, i) in 3" :key='i' style='pointer-events: none; overflow: hidden; box-shadow: none'>
						<span class='skeleton' :style='`width: 100%; height: 62px`' />
					</div>
				</div>
				<div class="a-favourite-preloader" v-else-if='!loading && initialItems.length === 0'>
					<div>
						<div class='a-favourite-preloader-icons'>
							<v-icon class='a-favourite-preloader-icon big' icon='favourite' :size='156' />
							<v-icon class='a-favourite-preloader-icon small' icon='favourite' :size='87' />
						</div>
						<p class="a-heading-1 a-favourite-preloader-title">{{$t("account.no_favorites")}}</p>
						<p class='a-favourite-preloader-text'>{{$t("account.unfortunately_there_are_no_selected_hotels")}}</p>
					</div>
				</div>
				<div class='a-favourite-content' v-else>
					<div v-for="(group, i) in items" :key='i'>
						<v-accordion hideTriggerIcon>
							<template v-slot:title>
								<v-icon icon='arrow-down' :size='14' />
								{{ i }}
							</template>

							<template v-slot:content>
								<trips-card
									v-for='(item, i) in group' :key='i'
									:item='item'
									@update-favourite='hotelFavorite'
								/>
							</template>
						</v-accordion>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TripsCard from '@/views/account/favourite/card-hotel';
	import { hotelFavorite } from '@/services/request';

	export default {
		data: () => ({
			Accordion: {
				count: 0,
				active: null
			},
			initialItems: [],
			items: [],
			loading: true
		}),
		provide() {
			return { Accordion: this.Accordion }
		},
		components: {
			TripsCard
		},
		created() {
			this.hotelFavorite();
		},
		methods: {
			hotelFavorite() {
				this.loading = true;
				hotelFavorite({
					search: this.search
				}).then(res => {
					this.initialItems = res.data;
					this.items = this.$_.groupBy(res.data, 'countryName');
				}).finally(() => this.loading = false)
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-favourite {
		padding: 30px 0 80px;
		display: grid;
		min-height: calc(100vh - 70px);
		@include minw( $grid-breakpoints-sm ) {
			padding: 80px 0;
			min-height: calc(100vh - 105px * .8);
		}
		@include minw( $grid-breakpoints-md ) {
			min-height: calc(100vh - 105px);
		}
		&-container {
			max-width: 1150px;
		}
		&-header {
			display: flex;
			align-items: center;
			margin-bottom: 35px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				justify-content: center;
			}
			.app-icon {
				flex-shrink: 0;
				margin-right: 12px;
				svg {
					fill: none;
					stroke: currentColor;
				}
			}
		}
		&-content {
			.app-accordion {
				&__title {
					@include minw( $grid-breakpoints-lg ) {
						padding: 23px 30px;
					}
					.heading-4 {
						display: flex;
						align-items: center;
						@include minw( $grid-breakpoints-lg ) {
							font-size: 18px;
						}
						.app-icon {
							flex-shrink: 0;
							margin-right: 25px;
							color: $green-persian;
							transition: transform $transition;
						}
					}
				}
				&__trigger_active {
					.app-icon {
						transform: rotate(180deg);
					}
				}
				&__content {
					padding: 20px;
					@include minw($grid-breakpoints-xs) {
						padding: 0;
					}
				}
				&__item {
					box-shadow: 0px 0px 20px rgba(86, 86, 86, 0.2);
					margin-bottom: 15px;
				}
			}
			.h-card {
				&-wrap {
					&:last-child {
						.h-card-container {
							border: none;
						}
					}
					@include maxw($grid-breakpoints-xs - 1) {
						&:not(:last-child) {
							margin-bottom: 25px;
						}
					}
				}
				&-container {
					@include maxw($grid-breakpoints-xs - 1) {
						padding: 15px 0 25px;
					}
				}
				&-image {
					height: 195px;
					border-radius: 5px;
					@include minw($grid-breakpoints-xs) {
						width: 180px;
						height: 120px;
					}
				}
			}
		}
		&-preloader {
			padding: 40px 0;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			text-align: center;
			border-top: 1px solid rgba($black, .1);
			&-icons {
				width: 156px;
				height: 156px;
				margin: auto;
				margin-bottom: 45px;
			}
			&-icon {
				&.big {
					color: $green-persian;
				}
				&.small {
					position: absolute;
					bottom: 0;
					right: -10px;
					color: $cyan-light;
				}
			}
			&-title {
				margin-bottom: 16px;
			}
			&-text {
				font-size: 16px;
				font-weight: 400;
			}
		}
		.app-accordion__content {
			@include minw($grid-breakpoints-xs) {
				padding: 12px 6px;
			}
		}
	}
</style>
